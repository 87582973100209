import React, { useState } from 'react';

import Alert from '@rotaryintl/harris-alert';
import Link from '@rotaryintl/harris-link';
import List from '@rotaryintl/harris-list';
import { FormikErrors, FormikTouched } from 'formik';
import { useTranslation } from '@external/react-i18next';

import { OneColumn } from '@components/Layouts/OneColumn';
import RotaryMagazineForm from '@presenters/web/components/RotaryMagazine/RotaryMagazineForm';

import '../presenters/web/components/RotaryMagazine/styles.css';

interface ErrorValues {
  email: string;
  'first-name': string;
  'last-name': string;
  address: string;
  city: string;
  'country-dropdown': string;
  'state-dropdown': string;
  'postal-code': string;
  'club-name': string;
}

interface TouchedValues {
  email: boolean;
  'first-name': boolean;
  'last-name': boolean;
  address: boolean;
  city: boolean;
  'country-dropdown': boolean;
  'state-dropdown': boolean;
  'postal-code': boolean;
  'club-name': boolean;
}

const RotaryMagazinePage: React.FC = () => {
  const { t } = useTranslation();
  const [errorSummary, setErrorSummary] = useState<Errors>({});

  const handleSetErrors = (
    errors: FormikErrors<ErrorValues>,
    touched: FormikTouched<TouchedValues>
  ) => {
    let filteredErrors: FormikErrors<ErrorValues> = {};
    Object.keys(errors).forEach(key => {
      if (touched[key as keyof TouchedValues]) {
        filteredErrors = {
          ...filteredErrors,
          [key]: errors[key as keyof FormikErrors<ErrorValues>],
        };
      }
    });
    setErrorSummary(getCustomErrorMessage(filteredErrors, t));
  };

  type Errors = {
    [key: string]: string;
  };

  type CustomMessages = {
    [key: string]: {
      [errorMessage: string]: string;
    };
  };

  const getCustomErrorMessage = (
    errors: Errors,
    t: (key: string, defaultValue: string) => string
  ): Errors => {
    const customMessages: CustomMessages = {
      email: {
        'Email address is required': t(
          'rotary-magazine-form.email.required',
          'Email address is required.'
        ),
        'Invalid email address': t(
          'rotary-magazine-form.email.invalid',
          'Email address is invalid.'
        ),
      },
      'first-name': {
        'First name is required': t(
          'rotary-magazine-form.firstName.required',
          'First name is required.'
        ),
        'The following characters are not allowed: \\ / : * ? “ < > | # %': t(
          'rotary-magazine-form.firstName.invalidChars',
          'First name cannot contain invalid characters: \\ / : * ? “ < > | # %'
        ),
        'Field cannot contain non-latin characters': t(
          'rotary-magazine-form.firstName.nonLatin',
          'First name cannot contain non-latin characters.'
        ),
      },
      'last-name': {
        'Last name is required': t(
          'rotary-magazine-form.lastName.required',
          'Last name is required.'
        ),
        'The following characters are not allowed: \\ / : * ? “ < > | # %': t(
          'rotary-magazine-form.lastName.invalidChars',
          'Last name cannot contain invalid characters: \\ / : * ? “ < > | # %'
        ),
        'Field cannot contain non-latin characters': t(
          'rotary-magazine-form.lastName.nonLatin',
          'Last name cannot contain non-latin characters.'
        ),
      },
      address: {
        'Address line 1 cannot be empty': t(
          'rotary-magazine-form.address.required',
          'Address is required.'
        ),
      },
      'country-dropdown': {
        'Country/Region is required': t(
          'rotary-magazine-form.country.required',
          'Country/Region is required.'
        ),
      },
      city: {
        'City is required': t(
          'rotary-magazine-form.city.required',
          'City is required.'
        ),
        'Field requires minimum 2 characters': t(
          'rotary-magazine-form.city.min',
          'City requires a minimum of 2 characters.'
        ),
      },
      'state-dropdown': {
        'State/Province/Territory is required': t(
          'rotary-magazine-form.state.required',
          'State/Province/Territory is required.'
        ),
      },
      'postal-code': {
        'Postal code is required': t(
          'rotary-magazine-form.postalCode.required',
          'Postal code is required.'
        ),
      },
      'club-name': {
        'Club name is required': t(
          'rotary-magazine-form.clubName.required',
          'Club name is required.'
        ),
      },
    };

    return Object.keys(errors).reduce<Errors>((acc, key) => {
      const errorMessage = errors[key];

      if (customMessages[key]?.[errorMessage]) {
        return { ...acc, [key]: customMessages[key][errorMessage] };
      }

      return acc;
    }, {});
  };

  return (
    <OneColumn className="mb-20">
      <h1 className="rm-title text-gray-600 font-semibold mb-6 mt-8">
        {t('rotary-magazine.title', 'Rotary Magazine Digital Edition')}
      </h1>
      <section className="rm-content-wrapper">
        <p className="text-gray-600 text-base normal-case leading-6 mb-12">
          {t(
            'rotary-magazine.description',
            `As a digital subscriber, you will receive a link to the new issue in your e-mail inbox each month from GTxcel. If you're not currently subscribed to `
          )}
          <span className="italic">Rotary</span>
          {t(
            'rotary-magazine.description.continue',
            ` magazine (English only) or if you have any additional questions, please contact`
          )}
          <Link to="data@rotary.org" className="rm-link">
            data@rotary.org
          </Link>
          .
        </p>
        {Object.keys(errorSummary)?.length ? (
          <Alert
            isDismissible
            onClose={() => {}}
            variant="critical"
            className="mb-6"
          >
            <List listStyle="bulleted">
              {Object.keys(errorSummary).map(key => (
                <li key={key} className="rm-list-item">
                  {errorSummary[key]}
                </li>
              ))}
            </List>
          </Alert>
        ) : (
          ''
        )}
        <span className="text-gray-600 text-sm normal-case leading-5 inline-block mb-6">
          {t('rotary-magazine.required', '*indicates a required field')}
        </span>
        <h3 className="rm-sub-title">
          {t('rotary-magazine.subscription-title', 'Subscription information')}
        </h3>
        <RotaryMagazineForm onErrorsChange={handleSetErrors} />
      </section>
    </OneColumn>
  );
};

export default RotaryMagazinePage;
