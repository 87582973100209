import React, { useEffect, useRef, useState } from 'react';

import Button from '@rotaryintl/harris-button';
import Checkbox from '@rotaryintl/harris-form-basic/dist/CheckBox/Checkbox';
import Select from '@rotaryintl/harris-form-basic/dist/SelectField/Select';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import Link from '@rotaryintl/harris-link';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from 'formik';
import { useTranslation } from '@external/react-i18next';
import './styles-form.css';

import createValidationSchema from './RotaryMagazineValidationSchema';

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

interface RotaryMagazineFormProps {
  onErrorsChange: (
    errors: FormikErrors<InitialValues>,
    touched: FormikTouched<InitialValues>
  ) => void;
}

interface InitialValues {
  email: string;
  'first-name': string;
  'last-name': string;
  address: string;
  city: string;
  'club-name': string;
  'country-dropdown': string;
  'state-dropdown': string;
  'postal-code': string;
  'digital-version-checkbox': string[];
  'address-second-line'?: string; // Optional fields
  'address-third-line'?: string; // Optional fields
  'member-id': string;
}

const RotaryMagazineForm: React.FC<RotaryMagazineFormProps> = ({
  onErrorsChange,
}) => {
  const { t } = useTranslation();

  const validationSchema = createValidationSchema(t);

  const handleErrorsChange = (
    errors: FormikErrors<InitialValues>,
    touched: FormikTouched<InitialValues>
  ) => {
    onErrorsChange(errors, touched);
  };

  const initialValues: InitialValues = {
    email: '',
    'first-name': '',
    'last-name': '',
    address: '',
    city: '',
    'club-name': '',
    'country-dropdown': '',
    'state-dropdown': '',
    'postal-code': '',
    'digital-version-checkbox': [],
    'address-second-line': '',
    'address-third-line': '',
    'member-id': '',
  };

  const [allErrors, setAllErrors] = useState<FormikErrors<InitialValues>>({});
  const [allTouched, setAllTouched] = useState<FormikTouched<InitialValues>>(
    {}
  );

  const prevErrors = usePrevious(allErrors);
  const prevTouched = usePrevious(allTouched);

  const handleErrors = (
    errors: FormikErrors<InitialValues>,
    touched: FormikTouched<InitialValues>
  ) => {
    setAllErrors(errors);
    setAllTouched(touched);
  };

  useEffect(() => {
    if (prevErrors !== allErrors || prevTouched !== allTouched) {
      handleErrorsChange(allErrors, allTouched);
    }
  }, [allErrors, prevErrors, allTouched, prevTouched]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      // validateOnBlur
      onSubmit={(values, { setSubmitting }: FormikHelpers<InitialValues>) => {
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, errors, touched }: FormikProps<InitialValues>) => {
        handleErrors(errors, touched);

        return (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              name="email"
              label={t('rotary-magazine-form.email.label', 'Email address')}
              placeholder={t(
                'rotary-magazine-form.email.placeholder',
                'Your email'
              )}
              description={t(
                'rotary-magazine-form.email.description',
                'Email address where you’d like to receive correspondence from Rotary International and GTxcel.'
              )}
              icon="email"
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="first-name"
              label={t('rotary-magazine-form.firstName.label', 'First name')}
              placeholder={t(
                'rotary-magazine-form.firstName.placeholder',
                'First name'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="last-name"
              label={t('rotary-magazine-form.lastName.label', 'Last name')}
              placeholder={t(
                'rotary-magazine-form.lastName.placeholder',
                'Last name'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="address"
              label={t('rotary-magazine-form.address.label', 'Address')}
              placeholder={t(
                'rotary-magazine-form.address.placeholder',
                'Address'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="address-second-line"
              label={t(
                'rotary-magazine-form.addressSecondLine.label',
                'Address, second line'
              )}
              placeholder={t(
                'rotary-magazine-form.addressSecondLine.placeholder',
                'Address, second line (optional)'
              )}
              characters={250}
              className="mb-6"
            />
            <TextField
              name="address-third-line"
              label={t(
                'rotary-magazine-form.addressThirdLine.label',
                'Address, third line'
              )}
              placeholder={t(
                'rotary-magazine-form.addressThirdLine.placeholder',
                'Address, third line (optional)'
              )}
              characters={250}
              className="mb-6"
            />
            <Select
              name="country-dropdown"
              label="Country/Region"
              placeholder="Select..."
              options={[
                { value: 'a', label: 'Country A' },
                { value: 'b', label: 'Country B' },
                { value: 'c', label: 'Country C' },
              ]}
              required
              clearable
              className="mb-6"
            />
            <TextField
              name="city"
              label={t('rotary-magazine-form.city.label', 'City')}
              placeholder={t('rotary-magazine-form.city.placeholder', 'City')}
              characters={250}
              required
              className="mb-6"
            />
            <Select
              name="state-dropdown"
              label="State/Province/Territory"
              placeholder="Select..."
              options={[
                { value: 'a', label: 'State A' },
                { value: 'b', label: 'State B' },
                { value: 'c', label: 'State C' },
              ]}
              required
              clearable
              className="mb-6"
            />
            <TextField
              name="postal-code"
              label={t('rotary-magazine-form.postalCode.label', 'Postal code')}
              placeholder={t(
                'rotary-magazine-form.postalCode.placeholder',
                'Postal code'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="club-name"
              label={t('rotary-magazine-form.clubName.label', 'Club name')}
              placeholder={t(
                'rotary-magazine-form.clubName.placeholder',
                'Club name'
              )}
              characters={250}
              required
              notice={
                <>
                  {t('rotary-magazine-form.clubName.contactText', 'Contact ')}
                  <Link to="data@rotary.org" className="rm-link">
                    data@rotary.org
                  </Link>
                </>
              }
              className="mb-6"
            />
            <TextField
              name="member-id"
              label={t('rotary-magazine-form.memberId.label', 'Member ID')}
              placeholder={t(
                'rotary-magazine-form.memberId.placeholder',
                'Your member ID'
              )}
              characters={250}
              className="mb-6"
            />
            <Checkbox
              name="digital-version-checkbox"
              options={[
                {
                  label: (
                    <>
                      {t(
                        'rotary-magazine-form.digital.version',
                        'I elect to receive '
                      )}
                      <span className="italic">Rotary</span>
                      {t(
                        'rotary-magazine-form.digital.version',
                        ' magazine in digital form instead of the print edition.*'
                      )}
                    </>
                  ),
                  readonly: false,
                  value: '0',
                },
              ]}
              className="mb-6"
            />
            <p>
              {t(
                'rotary-magazine-form.privacyStatement',
                'Your privacy is important to Rotary and the personal data you share with Rotary will only be used for official Rotary business. This means that your personal data will be used to facilitate your subscription to the Rotary digital magazine. Personal data you provide when subscribing to the digital magazine may be transferred to Rotary service providers, including the provider of the digital magazine. You may receive information about your subscription from the magazine provider via email. Personal information collected is subject to '
              )}
              <Link to="data@rotary.org" className="rm-link">
                {t(
                  'rotary-magazine-form.privacyContact',
                  'Rotary’s Privacy policy'
                )}
              </Link>
              .
            </p>
            <section className="flex-1 mt-12">
              <Button
                variant="primary"
                disabled={false}
                clickHandler={handleSubmit}
                className="mr-6"
              >
                {t('rotary-magazine-form.save', 'Save')}
              </Button>
              <Button variant="text" disabled={false}>
                {t('rotary-magazine-form.cancel', 'Cancel')}
              </Button>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RotaryMagazineForm;
